// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/validation/login.schema.ts"
);
import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import { zfd } from 'zod-form-data';
import { z } from 'zod';

export const loginSchema = zfd.formData({
  username: zfd.text(z.string()),
  password: zfd.text(z.string()),
})


