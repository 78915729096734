// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import { json } from '@remix-run/node';
import { authenticator } from '../services/auth.server';
import InputField from '../components/form/InputField';
import { ValidatedForm } from 'remix-validated-form';
import { withZod } from '@remix-validated-form/with-zod';
import { loginSchema } from '../validation/login.schema';
import SubmitButton from '../components/form/SubmitButton';
import { useLoaderData } from '@remix-run/react';
import Title from '../components/common/Title';
import { getSession } from '../services/session.server';
const validator = withZod(loginSchema);

// Second, we need to export an action function, here we will use the
// `authenticator.authenticate method`
export const action = async ({
  request,
  context
}) => {
  const resp = await authenticator.authenticate('user-pass', request, {
    successRedirect: '/',
    failureRedirect: '/login',
    throwOnError: true,
    context
  });
  console.log(resp);
  return resp;
};

// Finally, we can export a loader function where we check if the user is
// authenticated with `authenticator.isAuthenticated` and redirect to the
// dashboard if it is or return null if it's not
export const loader = async ({
  request
}) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/'
  });
  const session = await getSession(request.headers.get('Cookie'));
  const error = session.get('sessionErrorKey');
  return json({
    error
  });
};
export default function Login() {
  _s();
  const loaderData = useLoaderData();
  console.log('loader data', loaderData);
  return <div className={'flex flex-col gap-4'}>
      <Title>Login</Title>

      {loaderData?.error && <div className={'text-red-500'}>Invalid username or password</div>}
      <ValidatedForm method="post" className={'flex flex-col gap-4'} validator={validator}>
        <InputField label="Username" name="username" color={'dark'} required />
        <InputField label="Password" name="password" color={'dark'} type="password" required />

        <SubmitButton>Log In</SubmitButton>
      </ValidatedForm>
    </div>;
}
_s(Login, "n9wT3BYrlyVy43K0tukABMUH7l4=", false, function () {
  return [useLoaderData];
});
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;